<template>
  <v-container fluid class="text-center">
    <v-navigation-drawer app right v-model="drawer" class="app-sidemenu" temporary width="300">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10" class="container-padding">
          <h2 class="titulo text-center">Filtros</h2>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.idsolicitud" label="ID Solicitud" prepend-icon="mdi-account" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.nombre_persona" label="Nombre del cliente" prepend-icon="mdi-account" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete v-model="filters.grupos" label="Grupo" :items="group" item-text="nombre" item-value="nombre" prepend-icon="mdi-account-group" :disabled="loading" multiple small-chips outlined dense required>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.nombre }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ filters.grupos.length - 1 }} más)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete v-model="filters.estado_solicitud" label="Estado de solicitud" :items="status" prepend-icon="mdi-file" :disabled="loading" outlined dense required></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <Date-Picker v-model="filters.fecha_creacion_inicio" label="Fecha inicio de creación" />
        </v-col>
        <v-col cols="12" md="12">
          <Date-Picker v-model="filters.fecha_creacion_fin" label="Fecha fin de creación" />
        </v-col>
        <v-divider />
        <v-col cols="12" md="12">
          <h2 class="primary--text">Direccion de cliente</h2>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.calle" label="Calle" prepend-icon="mdi-home-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.colonia" label="Colonia" prepend-icon="mdi-home-group" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.estado" label="Estado" prepend-icon="mdi-city" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.pais" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model.number="filters.direccion_persona.cp" label="Código postal" prepend-icon="mdi-pound" :disabled="loading" type="number" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <Basic-Btn text="Reiniciar" block color="secondary" icon="mdi-filter-remove" @click="resetFilters()" :disabled="loading"/>
          <Basic-Btn text="Buscar" block color="primary" icon="mdi-magnify" @click="getRequest()" :loading="loading"/>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <Loader :loading="loading" :animation="animation" />
    <Table-Container title="Solicitudes" cardTextFooter>
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="getRequest()" :loading="loading" />
        <Basic-Btn text="Descargar" color="primary" icon="mdi-download" @click="downloadReport()" :loading="loading" />
      </template>
      <template v-slot:search>
        <Basic-Btn text="Filtros" color="primary" icon="mdi-filter" @click="drawer = !drawer" :loading="loading" />
      </template>
      <v-data-table :headers="headers" :items="items" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.cliente.persona`]="{ item }">
          {{ item.cliente ? item.cliente.persona.nombre + " " + item.cliente.persona.apellido_paterno + " " + item.cliente.persona.apellido_materno : "Desconocido" }}
        </template>
        <template v-slot:[`item.monto_sugerido`]="{ item }">
          {{ item.monto_sugerido ? "$ " + formatNumberToMoney(item.monto_sugerido) : "Desconocido" }}
        </template>
        <template v-slot:[`item.monto_solicitado`]="{ item }">
          {{ item.monto_solicitado ? "$ " + formatNumberToMoney(item.monto_solicitado) : "Desconocido" }}
        </template>
        <template v-slot:[`item.monto_aprobado`]="{ item }">
          {{ item.monto_aprobado ? "$ " + formatNumberToMoney(item.monto_aprobado) : "Desconocido" }}
        </template>
        <template v-slot:[`item.monto_entregado`]="{ item }">
          {{ item.monto_entregado ? "$ " + formatNumberToMoney(item.monto_entregado) : "Desconocido" }}
        </template>
        <template v-slot:[`item.folio_tarjeta`]="{ item }">
          {{ item.folio_tarjeta ? item.folio_tarjeta : "Desconocido" }}
        </template>
            <template v-slot:[`item.cliente.persona.clave_elector`]="{ item }">
          {{ item.cliente.persona.clave_elector ? item.cliente.persona.clave_elector : "Desconocido" }}
        </template>
            <template v-slot:[`item.grupo.nombre`]="{ item }">
          {{ item.grupo.nombre ? item.grupo.nombre : "Desconocido" }}
        </template>
            <template v-slot:[`item.cliente.persona.telefono_movil`]="{ item }">
          {{ item.cliente.persona.telefono_movil ? item.cliente.persona.telefono_movil : "Desconocido" }}
        </template>
            <template v-slot:[`item.empresa.nombre`]="{ item }">
          {{ item.empresa.nombre ? item.empresa.nombre : "Desconocido" }}
        </template>
        <template v-slot:[`item.fecha_inicio_credito`]="{ item }">
          {{ dateFormat(item.fecha_inicio_credito) }}
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.estatus_solicitudes_prestamos`]="{ item }">
          {{ returnRequestState(item.estatus_solicitudes_prestamos.length > 0 ? item.estatus_solicitudes_prestamos[0].estatus.nombre : "no disponibe") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item.idsolicitud_prestamo)">mdi-eye</v-icon></template
            ><span>Ver detalle</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="goAprove(item.idsolicitud_prestamo)">mdi-file-check-outline</v-icon></template
            ><span>Ver flujo de aprobación</span></v-tooltip
          >
          <v-tooltip top v-if="item.estatus_solicitudes_prestamos[0].estatus.nombre == 'ACTIVO'"
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="secondary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="goPay(item.idsolicitud_prestamo)">mdi-cash</v-icon></template
            ><span>Pagos</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getMyFiles(item)">mdi-file-document-outline</v-icon></template
            ><span>Ver archivos</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="success" dark v-bind="attrs" v-on="on" :disabled="loading" @click="openFile(item)">mdi-file-upload-outline</v-icon></template
            ><span>Subir archivos</span></v-tooltip
          >
                    

        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
      <template v-slot:text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-select v-model="listPage" label="Pagina" :items="[20, 50]" outlined dense></v-select>
          </v-col>
          <v-col cols="2" class="container-padding">
            <v-pagination v-model="page" :length="limitPage" :total-visible="10"></v-pagination>
          </v-col>
        </v-row>
      </template>
    </Table-Container>
    <Dialog-Form :estate="dialogs.file" title="Subir archivos">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="addFile()" :loading="loading" />
      </template>
      <v-container>
        <v-row>
          <v-col cols="6" class="text-left"> <b>Nombre: </b> {{ requestFileUse.name }} </v-col>
          <v-col cols="6" class="text-right"> <b>Estatus: </b> {{ returnRequestState(requestFileUse.status.length > 0 ? requestFileUse.status[0].estatus.nombre : "no disponibe") }} </v-col>
        </v-row>
      </v-container>
      <v-form ref="formFile" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.file.tipo" :items="fileTypes" hide-no-data hide-selected item-text="text" item-value="value" label="Tipo de archivo" prepend-icon="mdi-file-document-outline" :rules="rules.valid" :loading="loading" outlined dense outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
          <Date-Picker v-model="forms.file.fecha_vigencia" label="Fecha de vigencia" offLimit />
          </v-col>
            <v-col cols="12" md="12">
            <v-file-input v-model="forms.file.file" label="Archivo" prepend-icon="mdi-paperclip" :rules="rules.valid" show-size :loading="loading" outlined dense outlined dense required></v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.myFiles" title="Archivos">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
      </template>
      <v-container>
        <v-row>
          <v-col cols="6" class="text-left"> <b>Nombre: </b> {{ requestFileUse.name }} </v-col>
          <v-col cols="6" class="text-right"> <b>Estatus: </b> {{ returnRequestState(requestFileUse.status.length > 0 ? requestFileUse.status[0].estatus.nombre : "no disponibe") }} </v-col>
        </v-row>
      </v-container>
      <v-data-table :headers="headersFiles" :items="itemsFiles" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.tipo`]="{ item }"> {{ nameFile(item.tipo) }} </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="downloadImage(item)">mdi-file-download-outline</v-icon></template
            ><span>Descargar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="removeFile(item)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Dialog-Form>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el archivo?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="removeFile()" :loading="loading" />
      </template>
    </Delete-Message>
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, FillterList, DatePicker, } from "@/components";
import services from "@/utils/services";
import moment from "moment";
import downloadjs from "downloadjs";

export default {
  name: "loan",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    FillterList,
    DatePicker,
  },
  data: () => ({
    drawer: false,
    group: null,
    deleted: null,
    animation: "loader",
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    fileTypes: [
      {
        text: "Foto de identificación",
        value: "FOTO_IDENTIFICACION",
      },
      {
        text: "Foto de identificación reverso",
        value: "FOTO_IDENTIFICACION_REVERSO",
      },
      {
        text: "Foto de comprobante de domicilio",
        value: "FOTO_COMPROBANTE_DOMICILIO",
      },
      {
        text: "Identificación oficial",
        value: "IDENTIFICACION_OFICIAL",
      },
      {
        text: "Comprobante de domicilio",
        value: "COMPROBANTE_DOMICILIO",
      },
      {
        text: "Documento legal",
        value: "DOCUMENTO_LEGAL",
      },
      {
        text: "Predial",
        value: "PREDIAL",
      },
      {
        text: "Reglamento",
        value: "REGLAMENTO",
      },
      {
        text: "Comprobante de propiedad",
        value: "COMPROBANTE_PROPIEDAD",
      },
      {
        text: "Pagare",
        value: "PAGARE",
      },
      {
        text: "Otro",
        value: "OTRO",
      },
    ],
    dialogs: {
      file: false,
      myFiles: false,
      remove: false,
    },
    forms: {
      file: {
        solicitud: null,
        tipo: null,
        fecha_vigencia: '',
        file: null,
      },
    },
    loading: false,
    headers: [
      {
        text: "ID",
        value: "idsolicitud_prestamo",
      },
      { text: "Clave de elector", value: "cliente.persona.clave_elector" },
      { text: "Nombre", value: "cliente.persona" },
      { text: "Grupo", value: "grupo.nombre" },
      { text: "Telefono movil", value: "cliente.persona.telefono_movil" },
      { text: "Nombre de empresa", value: "empresa.nombre" },
      { text: "Estatus", value: "estatus_solicitudes_prestamos" },
      { text: "Semana", value: "semana" },
      { text: "Fecha Inicio crédito", value: "fecha_inicio_credito" },
      { text: "Fecha de creacion", value: "fecha_creacion" },
      { text: "Monto sugerido", value: "monto_sugerido" },
      { text: "Monto solicitado", value: "monto_solicitado" },
      { text: "Monto aprobado", value: "monto_aprobado" },
      { text: "Monto entregado", value: "monto_entregado" },
      { text: "Folio de tarjeta", value: "folio_tarjeta" },
      { text: "Acciones", value: "actions" },
    ],
    headersFiles: [
      { text: "Tipo de documento", value: "tipo" },
      { text: "Fecha de creacion", value: "fecha_creacion" },
      { text: "Acciones", value: "actions" },
    ],
    rules: {
      valid: [(v) => !!v || "El campo es requerido"],
    },
    requestFileUse: {
      name: "",
      status: "",
    },
    filters: {
      idsolicitud: '',
      nombre_persona:'',
      grupos: [],
      estado_solicitud:'',
      fecha_creacion_inicio:'',
      fecha_creacion_fin:'',
      direccion_persona: {
        calle: '',
        colonia: '',
        estado: '',
        municipio: '',
        pais: '',
        cp: '',
      },
    },
    items: [],
    status: [],
    group: [],
    itemsFiles: [],
    page: 1,
    limitPage: 1,
    listPage: 20,
    formats: ["image/jpeg", "image/jpg", "image/png", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf", "application/octet-stream"],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
    page() {
      this.getRequest();
    },
    listPage() {
      this.getRequest();
    },
  },
  mounted() {
    this.status = services.statusType;
    this.getRequest();
    this.getGroup()
  },
  methods: {
    getRequest() {
      this.loading = true;
      let sendFilters = {}
      for (let key in this.filters) {
        if (this.filters[key]) {
          sendFilters[key] = this.filters[key];
        }
      }
      sendFilters['direccion_persona'] = {}
      for (let key2 in this.filters['direccion_persona']) {
        if (this.filters['direccion_persona'][key2]) {
          sendFilters['direccion_persona'][key2] = this.filters['direccion_persona'][key2];
        }
      }
      let request = {
        params: {
          page_size: this.listPage,
          page_number: this.page,
          filters: sendFilters,
        },
      };
      this.axios.get(services.routes.loan + "/", request)
        .then((response) => {
          let data = response.data.data;
          this.items = data.values;
          this.limitPage = data.pages;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMyFiles(item) {
      if (!item.id) {
        this.requestFileUse.name = item.cliente.persona.nombre + " " + item.cliente.persona.apellido_paterno + " " + item.cliente.persona.apellido_materno;
        this.requestFileUse.status = item.estatus_solicitudes_prestamos;
        this.requestFileUse.id = item.idsolicitud_prestamo;
      }
      this.dialogs.myFiles = true;
      this.loading = true;
      let request = {
        params: {
          id: this.requestFileUse.id,
        },
      };
      this.axios
        .get(services.routes.loan + "/document/", request)
        .then((response) => {
          let data = response.data.data;
          this.itemsFiles = data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadImage(item) {
      this.animation = "downloader";
      this.loading = true;
      let url = services.routes.loan;
      this.axios({
        method: "get",
        url: url + "/file?id=" + this.requestFileUse.id + "&codigo=" + item.nombre,
        responseType: "blob",
      })
        .then((response) => {
          let fileName = response.headers["content-disposition"].split("attachment; filename=");
          downloadjs(response.data, fileName[1]);
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.animation = "loader";
        });
    },
    downloadReport() {
      this.animation = "downloader";
      this.loading = true;
      let url = services.routes.loan;
      this.axios({
        method: "get",
        url: url + "/report",
        responseType: "blob",
      })
        .then((response) => {
          let fileName = response.headers["content-disposition"].split("attachment; filename=");
          downloadjs(response.data, fileName[1]);
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.animation = "loader";
        });
    },
    removeFile(item) {
      if (!item) {
        let code = this.deleted.nombre;
        this.loading = true;
        let request = {
          params: {
            id: this.requestFileUse.id,
            codigo: code,
          },
        };
        this.axios
          .delete(services.routes.loan + "/file/", request)
          .then((response) => {})
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.getMyFiles(this.requestFileUse);
            this.loading = false;
            this.dialogs.remove = false;
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Archivo eliminado correctamente.";
            this.deleted = null;
          });
      } else {
        this.deleted = item;
        this.dialogs.remove = true;
      }
    },
    view(id) {
      this.$router.push("/detalle-solicitud/" + id);
    },
    dateFormat(date) {
      return (date) ? moment(date).format("DD/MM/YYYY") : 'N/A';
    },
    addFile() {
      this.animation = "uploader";
      if (this.$refs.formFile.validate()) {
        if (this.forms.file.file.size <= 10000000) {
          if (this.validFormat(this.forms.file.file.type)) {
            this.loading = true;
            let info = new FormData();
            for (let key in this.forms.file) {
              info.append(key, this.forms.file[key]);
            }
            this.axios
              .post(services.routes.loan + "/file", info)
              .then((response) => {
                let res = response.data;
              })
              .catch((error) => {
                this.alert.active = true;
                this.alert.color = "error";
                this.alert.msg = error;
              })
              .finally(() => {
                this.loading = false;
                this.alert.active = true;
                this.alert.color = "success";
                this.alert.msg = "Imagen actualizada correctamente";
                this.cancel();
              });
          } else {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = "Formato no valido.";
          }
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Documento supera los 10MB permitidos.";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta.";
      }
    },
    validFormat(format) {
      let valid = false;
      for (let type of this.formats) {
        if (format == type) {
          valid = true;
        }
      }
      return valid;
    },
    openFile(item) {
      this.requestFileUse.name = item.cliente.persona.nombre + " " + item.cliente.persona.apellido_paterno + " " + item.cliente.persona.apellido_materno;
      this.requestFileUse.status = item.estatus_solicitudes_prestamos;
      this.requestFileUse.id = item.idsolicitud_prestamo;
      this.forms.file.solicitud = item.idsolicitud_prestamo;
      this.dialogs.file = true;
    },
    cancel() {
      if (this.dialogs.file) {
        this.$refs.formFile.reset();
        for (let key in this.forms.file) {
          this.forms.file[key] = null;
        }
        this.$refs.formFile.resetValidation();
      } else if (this.dialogs.myFiles) {
        this.itemsFiles = [];
      }
      this.dialogs.file = false;
      this.dialogs.remove = false;
      this.dialogs.myFiles = false;
      this.animation = "loader";
    },
    nameFile(type) {
      let name = "Desconocido";
      for (let i of this.fileTypes) {
        if (type == i.value) {
          name = i.text;
          break;
        }
      }
      return name;
    },
    returnRequestState(info) {
      let i = info.length;
      let msg = "Sin solicitudes";
      if (i > 0) {
        msg = info[0].toUpperCase() + info.slice(1);
      }
      return msg;
    },
    goAprove(id){
      this.$router.push("/flujo-aprobacion/" + id);
    },
    goPay(id){
      this.$router.push('/pagos/' + id)
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
    async getGroup() {
      this.axios.get(services.routes.group)
        .then((response) => {
          this.group = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetFilters() {
      this.filters = {
        idsolicitud: '',
        nombre_persona: '',
        grupos: [],
        estado_solicitud: '',
        fecha_creacion_inicio: '',
        fecha_creacion_fin: '',
        direccion_persona: {
          calle: '',
          colonia: '',
          estado: '',
          municipio: '',
          pais: '',
          cp: '',
        },
      }
    },
  },
};
</script>

<style scoped>
  .app-sidemenu {
    z-index: 99;
  }
</style>
